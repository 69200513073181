import React from 'react'
import SEO from '../components/molecules/SEO'
import Copy from '../components/organisms/Copy';

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found"/>
    <Copy>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Copy>
  </>
);

export default NotFoundPage
